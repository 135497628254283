import React from 'react'
import { graphql } from 'gatsby'
import styles from './forms.module.sass'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Card from '../../components/card'

// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

const FormsPage = ({ data }) => (
   <Layout>
      <SEO title="Client Forms &amp; Documents" />
      <div className={['page', styles.page__forms].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Forms &amp; Documents
            </h1>
         </div>

         <div className={styles.forms}>
            {data.allContentfulAsset &&
               data.allContentfulAsset.edges.map(({ node }, index) => (
                  <Card
                     key={index}
                     type="form"
                     title={node.title}
                     content={
                        node.description
                           ? node.description
                           : node.file.fileName.includes('2B')
                           ? 'A brochure supplement which provides information related to ' +
                             node.title +
                             ' supplementing the SPC Financial, Inc. ADV brochure.'
                           : 'nocontent'
                     }
                     actionLink={node.file.url}
                     actionText="View Document"
                  />
               ))}
         </div>
      </div>
   </Layout>
)

export default FormsPage

export const query = graphql`
   query {
      allContentfulAsset(
         filter: { file: { contentType: { eq: "application/pdf" } } }
         sort: { fields: [title], order: ASC }
      ) {
         edges {
            node {
               file {
                  url
                  fileName
               }
               title
               description
            }
         }
      }
   }
`
